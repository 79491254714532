export const NON_EUROPEAN_COUNTRIES = {
  AFGHANISTAN: {
    id: 4,
    label: 'Afghanistan',
    value: 'afghanistan',
  },
  ALGERIA: {
    id: 999,
    label: 'Algeria',
    value: 'algeria',
  },
  AMERICAN_SAMOA: {
    id: 16,
    label: 'American Samoa',
    value: 'americanSamoa',
  },
  ANGOLA: {
    id: 1818,
    label: 'Angola',
    value: 'angola',
  },
  ANGUILLA: {
    id: 660,
    label: 'Anguilla',
    value: 'anguilla',
  },
  ANTIGUA_AND_BARBUDA: {
    id: 28,
    label: 'Antigua And Barbuda',
    value: 'antiguaAndBarbuda',
  },
  ARGENTINA: {
    id: 32,
    label: 'Argentina',
    value: 'argentina',
  },
  ARMENIA: {
    id: 51,
    label: 'Armenia',
    value: 'armenia',
  },
  ARUBA: {
    id: 533,
    label: 'Aruba',
    value: 'aruba',
  },
  AUSTRALIA: {
    id: 36,
    label: 'Australia',
    value: 'australia',
  },
  AZERBAIJAN: {
    id: 31,
    label: 'Azerbaijan',
    value: 'azerbaijan',
  },
  BAHAMAS: {
    id: 44,
    label: 'Bahamas',
    value: 'bahamas',
  },
  BAHRAIN: {
    id: 48,
    label: 'Bahrain',
    value: 'bahrain',
  },
  BANGLADESH: {
    id: 880,
    label: 'Bangladesh',
    value: 'bangladesh',
  },
  BARBADOS: {
    id: 52,
    label: 'Barbados',
    value: 'barbados',
  },
  BELIZE: {
    id: 501,
    label: 'Belize',
    value: 'belize',
  },
  BENIN: {
    id: 204,
    label: 'Benin',
    value: 'benin',
  },
  BERMUDA: {
    id: 60,
    label: 'Bermuda',
    value: 'bermuda',
  },
  BHUTAN: {
    id: 64,
    label: 'Bhutan',
    value: 'bhutan',
  },
  BOLIVIA: {
    id: 68,
    label: 'Bolivia',
    value: 'bolivia',
  },
  BONAIRE: {
    id: 535,
    label: 'Bonaire',
    value: 'bonaire',
  },
  BRAZIL: {
    id: 76,
    label: 'Brazil',
    value: 'brazil',
  },
  BRITISH_INDIAN_OCEAN_TERRITORY: {
    id: 86,
    label: 'British Indian Ocean Territory',
    value: 'britishIndianOceanTerritory',
  },
  BRITISH_VIRGIN_ISLANDS: {
    id: 92,
    label: 'British Virgin Islands',
    value: 'britishVirginIslands',
  },
  BRUNEI_DARUSSALAM: {
    id: 96,
    label: 'Brunei Darussalam',
    value: 'bruneiDarussalam',
  },
  CAMBODIA: {
    id: 116,
    label: 'Cambodia',
    value: 'cambodia',
  },
  CAMEROON: {
    id: 120,
    label: 'Cameroon',
    value: 'cameroon',
  },
  CANADA: {
    id: 124,
    label: 'Canada',
    value: 'canada',
  },
  CANARY_ISLANDS: {
    id: 1811,
    label: 'Canary Islands',
    value: 'canaryIslands',
  },
  CAPE_VERDE: {
    id: 132,
    label: 'Cape Verde',
    value: 'capeVerde',
  },
  CAYMAN_ISLANDS: {
    id: 136,
    label: 'Cayman Islands',
    value: 'caymanIslands',
  },
  CHANNEL_ISLANDS: {
    id: 830,
    label: 'Channel Islands',
    value: 'channelIslands',
  },
  CHILE: {
    id: 152,
    label: 'Chile',
    value: 'chile',
  },
  CHINA: {
    id: 156,
    label: 'China',
    value: 'china',
  },
  COLOMBIA: {
    id: 170,
    label: 'Colombia',
    value: 'colombia',
  },
  COOK_ISLANDS: {
    id: 184,
    label: 'Cook Islands',
    value: 'cookIslands',
  },
  COSTA_RICA: {
    id: 188,
    label: 'Costa Rica',
    value: 'costaRica',
  },
  CUBA: {
    id: 192,
    label: 'Cuba',
    value: 'cuba',
  },
  CURACAO: {
    id: 531,
    label: 'Curacao',
    value: 'curacao',
  },
  CYPRUS: {
    id: 196,
    label: 'Cyprus',
    value: 'cyprus',
  },
  DOMINICAN_REPUBLIC: {
    id: 1809,
    label: 'Dominican Republic',
    value: 'dominicanRepublic',
  },
  ECUADOR: {
    id: 218,
    label: 'Ecuador',
    value: 'ecuador',
  },
  EGYPT: {
    id: 818,
    label: 'Egypt',
    value: 'egypt',
  },
  EL_SALVADOR: {
    id: 222,
    label: 'El Salvador',
    value: 'elSalvador',
  },
  ERITREA: {
    id: 232,
    label: 'Eritrea',
    value: 'eritrea',
  },
  FALKLAND_ISLANDS: {
    id: 238,
    label: 'Falkland Islands (Islas Malvinas)',
    value: 'falklandIslands',
  },
  FIJI: {
    id: 242,
    label: 'Fiji',
    value: 'fiji',
  },
  FRENCH_GUIANA: {
    id: 254,
    label: 'French Guiana',
    value: 'frenchGuiana',
  },
  FRENCH_POLYNESIA: {
    id: 258,
    label: 'French Polynesia',
    value: 'frenchPolynesia',
  },
  GEORGIA: {
    id: 268,
    label: 'Georgia',
    value: 'georgia',
  },
  GREENLAND: {
    id: 304,
    label: 'Greenland',
    value: 'greenland',
  },
  GRENADA: {
    id: 308,
    label: 'Grenada',
    value: 'grenada',
  },
  GUADELOUPE: {
    id: 312,
    label: 'Guadeloupe',
    value: 'guadeloupe',
  },
  GUAM: {
    id: 316,
    label: 'Guam',
    value: 'guam',
  },
  GUATEMALA: {
    id: 320,
    label: 'Guatemala',
    value: 'guatemala',
  },
  GUYANA: {
    id: 328,
    label: 'Guyana',
    value: 'guyana',
  },
  HAITI: {
    id: 332,
    label: 'Haiti',
    value: 'haiti',
  },
  HONDURAS: {
    id: 340,
    label: 'Honduras',
    value: 'honduras',
  },
  HONG_KONG: {
    id: 344,
    label: 'Hong Kong',
    value: 'hongKong',
  },
  INDIA: {
    id: 356,
    label: 'India',
    value: 'india',
  },
  INDONESIA: {
    id: 360,
    label: 'Indonesia',
    value: 'indonesia',
  },
  IRAN: {
    id: 1814,
    label: 'Iran',
    value: 'iran',
  },
  ISRAEL: {
    id: 376,
    label: 'Israel',
    value: 'israel',
  },
  JAMAICA: {
    id: 388,
    label: 'Jamaica',
    value: 'jamaica',
  },
  JAPAN: {
    id: 392,
    label: 'Japan',
    value: 'japan',
  },
  JORDAN: {
    id: 400,
    label: 'Jordan',
    value: 'jordan',
  },
  KAZAKHSTAN: {
    id: 7,
    label: 'Kazakhstan',
    value: 'kazakhstan',
  },
  KENYA: {
    id: 404,
    label: 'Kenya',
    value: 'kenya',
  },
  KUWAIT: {
    id: 414,
    label: 'Kuwait',
    value: 'kuwait',
  },
  KYRGYZSTAN: {
    id: 417,
    label: 'Kyrgyzstan',
    value: 'kyrgyzstan',
  },
  LEBANON: {
    id: 422,
    label: 'Lebanon',
    value: 'lebanon',
  },
  LESOTHO: {
    id: 426,
    label: 'Lesotho',
    value: 'lesotho',
  },
  LIBYAN_ARAB_JAMAHIRIYA: {
    id: 434,
    label: 'Libyan Arab Jamahiriya',
    value: 'libyanArabJamahiriya',
  },
  MACAO: {
    id: 446,
    label: 'Macao',
    value: 'macao',
  },
  MADAGASCAR: {
    id: 450,
    label: 'Madagascar',
    value: 'madagascar',
  },
  MALAYSIA: {
    id: 458,
    label: 'Malaysia',
    value: 'malaysia',
  },
  MALDIVES: {
    id: 462,
    label: 'Maldives',
    value: 'maldives',
  },
  MALI: {
    id: 466,
    label: 'Mali',
    value: 'mali',
  },
  MARSHALL_ISLANDS: {
    id: 584,
    label: 'Marshall Islands',
    value: 'marshallIslands',
  },
  MARTINIQUE: {
    id: 474,
    label: 'Martinique',
    value: 'martinique',
  },
  MAURITIUS: {
    id: 480,
    label: 'Mauritius',
    value: 'mauritius',
  },
  MEXICO: {
    id: 484,
    label: 'Mexico',
    value: 'mexico',
  },
  MICRONESIA: {
    id: 583,
    label: 'Micronesia',
    value: 'micronesia',
  },
  MONGOLIA: {
    id: 496,
    label: 'Mongolia',
    value: 'mongolia',
  },
  MONTSERRAT: {
    id: 500,
    label: 'Montserrat',
    value: 'montserrat',
  },
  MOROCCO: {
    id: 504,
    label: 'Morocco',
    value: 'morocco',
  },
  MOUNT_ATHOS_GREECE: {
    id: 895,
    label: 'Mount Athos (Greece)',
    value: 'mountAthosGreece',
  },
  NAMIBIA: {
    id: 516,
    label: 'Namibia',
    value: 'namibia',
  },
  NETHERLANDS_ANTILLES: {
    id: 530,
    label: 'Netherlands Antilles',
    value: 'netherlandsAntilles',
  },
  NEW_CALEDONIA: {
    id: 1000,
    label: 'New Caledonia',
    value: 'newCaledonia',
  },
  NEW_ZEALAND: {
    id: 554,
    label: 'New Zealand',
    value: 'newZealand',
  },
  NICARAGUA: {
    id: 558,
    label: 'Nicaragua',
    value: 'nicaragua',
  },
  NIGERIA: {
    id: 897,
    label: 'Nigeria',
    value: 'nigeria',
  },
  NORFOLK_ISLAND: {
    id: 574,
    label: 'Norfolk Island',
    value: 'norfolkIsland',
  },
  NORTHERN_MARIANA_ISLANDS: {
    id: 580,
    label: 'Northern Mariana Islands',
    value: 'northernMarianaIslands',
  },
  OMAN: {
    id: 512,
    label: 'Oman',
    value: 'oman',
  },
  PAKISTAN: {
    id: 586,
    label: 'Pakistan',
    value: 'pakistan',
  },
  PANAMA: {
    id: 507,
    label: 'Panama',
    value: 'panama',
  },
  PAPUA_NEW_GUINEA: {
    id: 598,
    label: 'Papua New Guinea',
    value: 'papuaNewGuinea',
  },
  PERU: {
    id: 604,
    label: 'Peru',
    value: 'peru',
  },
  PHILIPPINES: {
    id: 608,
    label: 'Philippines',
    value: 'philippines',
  },
  PUERTO_RICO: {
    id: 630,
    label: 'Puerto Rico',
    value: 'puertoRico',
  },
  QATAR: {
    id: 634,
    label: 'Qatar',
    value: 'qatar',
  },
  REUNION: {
    id: 638,
    label: 'Reunion',
    value: 'reunion',
  },
  SAINT_KITTS_AND_NEVIS: {
    id: 659,
    label: 'Saint Kitts And Nevis',
    value: 'saintKittsAndNevis',
  },
  SAINT_LUCIA: {
    id: 662,
    label: 'Saint Lucia',
    value: 'saintLucia',
  },
  SAINT_MARTIN: {
    id: 1810,
    label: 'Saint Martin',
    value: 'saintMartin',
  },
  SAINT_VINCENT_AND_THE_GRENADINES: {
    id: 670,
    label: 'Saint Vincent And The Grenadines',
    value: 'saintVincentAndTheGrenadines',
  },
  SAMOA: {
    id: 882,
    label: 'Samoa',
    value: 'samoa',
  },
  SAUDI_ARABIA: {
    id: 682,
    label: 'Saudi Arabia',
    value: 'saudiArabia',
  },
  SENEGAL: {
    id: 686,
    label: 'Senegal',
    value: 'senegal',
  },
  SEYCHELLES: {
    id: 690,
    label: 'Seychelles',
    value: 'seychelles',
  },
  SINGAPORE: {
    id: 702,
    label: 'Singapore',
    value: 'singapore',
  },
  SINT_MAARTEN: {
    id: 534,
    label: 'Sint Maarten',
    value: 'sintMaarten',
  },
  SOLOMON_ISLANDS: {
    id: 90,
    label: 'Solomon Islands',
    value: 'solomonIslands',
  },
  SOUTH_AFRICA: {
    id: 710,
    label: 'South Africa',
    value: 'southAfrica',
  },
  SOUTH_KOREA: {
    id: 410,
    label: 'South Korea',
    value: 'southKorea',
  },
  SRI_LANKA: {
    id: 144,
    label: 'Sri Lanka',
    value: 'sriLanka',
  },
  SURINAME: {
    id: 740,
    label: 'Suriname',
    value: 'suriname',
  },
  SWAZILAND: {
    id: 748,
    label: 'Swaziland',
    value: 'swaziland',
  },
  TAIWAN: {
    id: 158,
    label: 'Taiwan',
    value: 'taiwan',
  },
  TANZANIA: {
    id: 834,
    label: 'Tanzania',
    value: 'tanzania',
  },
  THAILAND: {
    id: 764,
    label: 'Thailand',
    value: 'thailand',
  },
  TIMOR_LESTE: {
    id: 626,
    label: 'Timor-Leste',
    value: 'timorLeste',
  },
  TOGO: {
    id: 768,
    label: 'Togo',
    value: 'togo',
  },
  TONGA: {
    id: 776,
    label: 'Tonga',
    value: 'tonga',
  },
  TRINIDAD_AND_TOBAGO: {
    id: 780,
    label: 'Trinidad And Tobago',
    value: 'trinidadAndTobago',
  },
  TUNISIA: {
    id: 216,
    label: 'Tunisia',
    value: 'tunisia',
  },
  TURKEY: {
    id: 792,
    label: 'Turkey',
    value: 'turkey',
  },
  TURKS_AND_CAICOS_ISLANDS: {
    id: 1813,
    label: 'Turks And Caicos Islands',
    value: 'turksAndCaicosIslands',
  },
  UGANDA: {
    id: 1817,
    label: 'Uganda',
    value: 'uganda',
  },
  UNITED_ARAB_EMIRATES: {
    id: 784,
    label: 'United Arab Emirates',
    value: 'unitedArabEmirates',
  },
  UNITED_STATES: {
    id: 840,
    label: 'United States',
    value: 'unitedStates',
  },
  URUGUAY: {
    id: 858,
    label: 'Uruguay',
    value: 'uruguay',
  },
  US_VIRGIN_ISLANDS: {
    id: 850,
    label: 'US Virgin Islands',
    value: 'usVirginIslands',
  },
  UZBEKISTAN: {
    id: 1815,
    label: 'Uzbekistan',
    value: 'uzbekistan',
  },
  VANUATU: {
    id: 548,
    label: 'Vanuatu',
    value: 'vanuatu',
  },
  VENEZUELA: {
    id: 862,
    label: 'Venezuela',
    value: 'venezuela',
  },
  VIETNAM: {
    id: 84,
    label: 'Vietnam',
    value: 'vietnam',
  },
  ZIMBABWE: {
    id: 716,
    label: 'Zimbabwe',
    value: 'zimbabwe',
  },
}

export const EUROPEAN_COUNTRIES = {
  ALBANIA: {
    id: 1816,
    label: 'Albania',
    value: 'albania',
  },
  ANDORRA: {
    id: 20,
    label: 'Andorra',
    value: 'andorra',
  },
  AUSTRIA: {
    id: 40,
    label: 'Austria',
    value: 'austria',
  },
  BELGIUM: {
    id: 56,
    label: 'Belgium',
    value: 'belgium',
  },
  BOSNIA_AND_HERZEGOVINA: {
    id: 70,
    label: 'Bosnia and Herzegovina',
    value: 'bosniaAndHerzegovina',
  },
  BULGARIA: {
    id: 100,
    label: 'Bulgaria',
    value: 'bulgaria',
  },
  CROATIA_HRVATSKA: {
    id: 191,
    label: 'Croatia (Hrvatska)',
    value: 'croatiaHrvatska',
  },
  CZECH_REPUBLIC: {
    id: 203,
    label: 'Czech Republic',
    value: 'czechRepublic',
  },
  DENMARK: {
    id: 208,
    label: 'Denmark',
    value: 'denmark',
  },
  ESTONIA: {
    id: 233,
    label: 'Estonia',
    value: 'estonia',
  },
  FAROE_ISLANDS: {
    id: 234,
    label: 'Faroe Islands',
    value: 'faroeIslands',
  },
  FINLAND: {
    id: 246,
    label: 'Finland',
    value: 'finland',
  },
  FRANCE: {
    id: 250,
    label: 'France',
    value: 'france',
  },
  GERMANY: {
    id: 276,
    label: 'Germany',
    value: 'germany',
  },
  GIBRALTAR: {
    id: 292,
    label: 'Gibraltar',
    value: 'gibraltar',
  },
  GREECE: {
    id: 300,
    label: 'Greece',
    value: 'greece',
  },
  HUNGARY: {
    id: 348,
    label: 'Hungary',
    value: 'hungary',
  },
  ICELAND: {
    id: 352,
    label: 'Iceland',
    value: 'iceland',
  },
  IRELAND: {
    id: 372,
    label: 'Ireland',
    value: 'ireland',
  },
  ITALY: {
    id: 380,
    label: 'Italy',
    value: 'italy',
  },
  KOSOVO: {
    id: 926,
    label: 'Kosovo',
    value: 'kosovo',
  },
  LATVIA: {
    id: 428,
    label: 'Latvia',
    value: 'latvia',
  },
  LIECHTENSTEIN: {
    id: 438,
    label: 'Liechtenstein',
    value: 'liechtenstein',
  },
  LITHUANIA: {
    id: 440,
    label: 'Lithuania',
    value: 'lithuania',
  },
  LUXEMBOURG: {
    id: 442,
    label: 'Luxembourg',
    value: 'luxembourg',
  },
  MALTA: {
    id: 470,
    label: 'Malta',
    value: 'malta',
  },
  MOLDOVA: {
    id: 498,
    label: 'Moldova',
    value: 'moldova',
  },
  MONACO: {
    id: 492,
    label: 'Monaco',
    value: 'monaco',
  },
  MONTENEGRO: {
    id: 499,
    label: 'Montenegro',
    value: 'montenegro',
  },
  NETHERLANDS: {
    id: 528,
    label: 'Netherlands',
    value: 'netherlands',
  },
  NORWAY: {
    id: 578,
    label: 'Norway',
    value: 'norway',
  },
  POLAND: {
    id: 616,
    label: 'Poland',
    value: 'poland',
  },
  PORTUGAL: {
    id: 620,
    label: 'Portugal',
    value: 'portugal',
  },
  REPUBLIC_OF_MACEDONIA: {
    id: 807,
    label: 'Republic of Macedonia',
    value: 'republicOfMacedonia',
  },
  ROMANIA: {
    id: 642,
    label: 'Romania',
    value: 'romania',
  },
  RUSSIAN_FEDERATION: {
    id: 643,
    label: 'Russian Federation',
    value: 'russianFederation',
  },
  SAN_MARINO: {
    id: 674,
    label: 'San Marino',
    value: 'sanMarino',
  },
  SERBIA: {
    id: 381,
    label: 'Serbia',
    value: 'serbia',
  },
  SLOVAKIA: {
    id: 703,
    label: 'Slovakia',
    value: 'slovakia',
  },
  SLOVENIA: {
    id: 705,
    label: 'Slovenia',
    value: 'slovenia',
  },
  SPAIN: {
    id: 724,
    label: 'Spain',
    value: 'spain',
  },
  SWEDEN: {
    id: 752,
    label: 'Sweden',
    value: 'sweden',
  },
  SWITZERLAND: {
    id: 756,
    label: 'Switzerland',
    value: 'switzerland',
  },
  UKRAINE: {
    id: 804,
    label: 'Ukraine',
    value: 'ukraine',
  },
  UNITED_KINGDOM: {
    id: 826,
    label: 'United Kingdom',
    value: 'unitedKingdom',
  },
  VATICAN_CITY: {
    id: 336,
    label: 'Vatican City',
    value: 'vaticanCity',
  },
}

export const UE_COUNTRIES = {
  AUSTRIA: {
    id: 40,
    label: 'Austria',
    value: 'austria',
  },
  BELGIUM: {
    id: 56,
    label: 'Belgium',
    value: 'belgium',
  },
  BULGARIA: {
    id: 100,
    label: 'Bulgaria',
    value: 'bulgaria',
  },
  CROATIA: {
    id: 191,
    label: 'Croatia',
    value: 'croatia',
  },
  CYPRUS: {
    id: 196,
    label: 'Cyprus',
    value: 'cyprus',
  },
  CZECH_REPUBLIC: {
    id: 203,
    label: 'Czech Republic',
    value: 'czechRepublic',
  },
  DENMARK: {
    id: 208,
    label: 'Denmark',
    value: 'denmark',
  },
  ESTONIA: {
    id: 233,
    label: 'Estonia',
    value: 'estonia',
  },
  FINLAND: {
    id: 246,
    label: 'Finland',
    value: 'finland',
  },
  FRANCE: {
    id: 250,
    label: 'France',
    value: 'france',
  },
  GERMANY: {
    id: 276,
    label: 'Germany',
    value: 'germany',
  },
  GREECE: {
    id: 300,
    label: 'Greece',
    value: 'greece',
  },
  HUNGARY: {
    id: 348,
    label: 'Hungary',
    value: 'hungary',
  },
  IRELAND: {
    id: 372,
    label: 'Ireland',
    value: 'ireland',
  },
  ITALY: {
    id: 380,
    label: 'Italy',
    value: 'italy',
  },
  LATVIA: {
    id: 428,
    label: 'Latvia',
    value: 'latvia',
  },
  LITHUANIA: {
    id: 440,
    label: 'Lithuania',
    value: 'lithuania',
  },
  LUXEMBOURG: {
    id: 442,
    label: 'Luxembourg',
    value: 'luxembourg',
  },
  MALTA: {
    id: 470,
    label: 'Malta',
    value: 'malta',
  },
  NETHERLANDS: {
    id: 528,
    label: 'Netherlands',
    value: 'netherlands',
  },
  POLAND: {
    id: 616,
    label: 'Poland',
    value: 'poland',
  },
  PORTUGAL: {
    id: 620,
    label: 'Portugal',
    value: 'portugal',
  },
  ROMANIA: {
    id: 642,
    label: 'Romania',
    value: 'romania',
  },
  SLOVAKIA: {
    id: 703,
    label: 'Slovakia',
    value: 'slovakia',
  },
  SLOVENIA: {
    id: 705,
    label: 'Slovenia',
    value: 'slovenia',
  },
  SPAIN: {
    id: 724,
    label: 'Spain',
    value: 'spain',
  },
  SWEDEN: {
    id: 752,
    label: 'Sweden',
    value: 'sweden',
  },
  UNITED_KINGDOM: {
    id: 826,
    label: 'United Kingdom',
    value: 'unitedKingdom',
  },
  SWITZERLAND: {
    id: 756,
    label: 'Switzerland',
    value: 'switzerland',
  },
}

export const NON_EUROPEAN_COUNTRIES_ID = {
  Afghanistan: 4,
  Algeria: 999,
  'American Samoa': 16,
  Angola: 1818,
  Anguilla: 660,
  'Antigua and Barbuda': 28,
  Argentina: 32,
  Armenia: 51,
  Aruba: 533,
  Australia: 36,
  Azerbaijan: 31,
  Bahamas: 44,
  Bahrain: 48,
  Bangladesh: 880,
  Barbados: 52,
  Belize: 501,
  Benin: 204,
  Bermuda: 60,
  Bhutan: 64,
  Bolivia: 68,
  Bonaire: 535,
  Brazil: 76,
  'British Indian Ocean Territory': 86,
  'British Virgin Islands': 92,
  'Brunei Darussalam': 96,
  Cambodia: 116,
  Cameroon: 120,
  Canada: 124,
  'Canary Islands': 1811,
  'Cape Verde': 132,
  'Cayman Islands': 136,
  'Channel Islands': 830,
  Chile: 152,
  China: 156,
  Colombia: 170,
  'Cook Islands': 184,
  'Costa Rica': 188,
  Cuba: 192,
  Curaçao: 531,
  Cyprus: 196,
  'Dominican Republic': 1809,
  Ecuador: 218,
  Egypt: 818,
  'El Salvador': 222,
  Eritrea: 232,
  'Falkland Islands (Islas Malvinas)': 238,
  Fiji: 242,
  'French Guiana': 254,
  'French Polynesia': 258,
  Georgia: 268,
  Greenland: 304,
  Grenada: 308,
  Guadeloupe: 312,
  Guam: 316,
  Guatemala: 320,
  Guyana: 328,
  Haiti: 332,
  Honduras: 340,
  'Hong Kong': 344,
  India: 356,
  Indonesia: 360,
  Iran: 1814,
  Israel: 376,
  Jamaica: 388,
  Japan: 392,
  Jordan: 400,
  Kazakhstan: 7,
  Kenya: 404,
  Kuwait: 414,
  Kyrgyzstan: 417,
  Lebanon: 422,
  Lesotho: 426,
  'Libyan Arab Jamahiriya': 434,
  Macao: 446,
  Madagascar: 450,
  Malaysia: 458,
  Maldives: 462,
  Mali: 466,
  'Marshall Islands': 584,
  Martinique: 474,
  Mauritius: 480,
  Mexico: 484,
  Micronesia: 583,
  Mongolia: 496,
  Montserrat: 500,
  Morocco: 504,
  'Mount Athos (Greece)': 895,
  Namibia: 516,
  'Netherlands Antilles': 530,
  'New Caledonia': 1000,
  'New Zealand': 554,
  Nicaragua: 558,
  Nigeria: 897,
  'Norfolk Island': 574,
  'Northern Mariana Islands': 580,
  Oman: 512,
  Pakistan: 586,
  Panama: 507,
  'Papua New Guinea': 598,
  Peru: 604,
  Philippines: 608,
  'Puerto Rico': 630,
  Qatar: 634,
  Reunion: 638,
  'Saint Kitts And Nevis': 659,
  'Saint Lucia': 662,
  'Saint Martin': 1810,
  'Saint Vincent and the Grenadines': 670,
  Samoa: 882,
  'Saudi Arabia': 682,
  Senegal: 686,
  Seychelles: 690,
  Singapore: 702,
  'Sint Maarten': 534,
  'Solomon Islands': 90,
  'South Africa': 710,
  'South Korea': 410,
  'Sri Lanka': 144,
  Suriname: 740,
  Swaziland: 748,
  Taiwan: 158,
  Tanzania: 834,
  Thailand: 764,
  'Timor-Leste': 626,
  Togo: 768,
  Tonga: 776,
  'Trinidad And Tobago': 780,
  Tunisia: 216,
  Turkey: 792,
  'Turks and Caicos Islands': 1813,
  Uganda: 1817,
  'United Arab Emirates': 784,
  'United States': 840,
  Uruguay: 858,
  'US Virgin Islands': 850,
  Uzbekistan: 1815,
  Vanuatu: 548,
  Venezuela: 862,
  Vietnam: 84,
  Zimbabwe: 716,
}

export const EUROPEAN_COUNTRIES_ID = {
  Albania: 1816,
  Andorra: 20,
  Austria: 40,
  Belgium: 56,
  'Bosnia and Herzegovina': 70,
  Bulgaria: 100,
  'Croatia (Hrvatska)': 191,
  'Czech Republic': 203,
  Denmark: 208,
  Estonia: 233,
  'Faroe Islands': 234,
  Finland: 246,
  France: 250,
  Germany: 276,
  Gibraltar: 292,
  Greece: 300,
  Hungary: 348,
  Iceland: 352,
  Ireland: 372,
  Italy: 380,
  Kosovo: 926,
  Latvia: 428,
  Liechtenstein: 438,
  Lithuania: 440,
  Luxembourg: 442,
  Malta: 470,
  Moldova: 498,
  Monaco: 492,
  Montenegro: 499,
  Netherlands: 528,
  Norway: 578,
  Poland: 616,
  Portugal: 620,
  'Republic of Macedonia': 807,
  Romania: 642,
  'Russian Federation': 643,
  'San Marino': 674,
  Serbia: 381,
  Slovakia: 703,
  Slovenia: 705,
  Spain: 724,
  Sweden: 752,
  Switzerland: 756,
  Ukraine: 804,
  'United Kingdom': 826,
  'Vatican City': 336,
}

export const EU_COUNTRIES_ID = {
  Austria: 40,
  Belgium: 56,
  Bulgaria: 100,
  Croatia: 191,
  Cyprus: 196,
  CzechRepublic: 203,
  Denmark: 208,
  Estonia: 233,
  Finland: 246,
  France: 250,
  Germany: 276,
  Greece: 300,
  Hungary: 348,
  Ireland: 372,
  Italy: 380,
  Latvia: 428,
  Lithuania: 440,
  Luxembourg: 442,
  Malta: 470,
  Netherlands: 528,
  Poland: 616,
  Portugal: 620,
  Romania: 642,
  Slovakia: 703,
  Slovenia: 705,
  Spain: 724,
  Sweden: 752,
  UnitedKingdom: 826,
  Switzerland: 756,
}

export const NON_EU_COUNTRIES_ID = {
  'United Arab Emirates': 784,
}
