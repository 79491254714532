import { useNewSettingsStore } from '~/store/newSettingsStore'
import { EU_COUNTRIES_ID, EUROPEAN_COUNTRIES_ID, NON_EU_COUNTRIES_ID } from '~/constants/countries'

// Initializes the settings store within the function to ensure it's called only after Pinia has been attached to Nuxt.
// This avoids errors related to accessing the store too early.
const getCurrentCountryId = () => {
  const { currentCountry, userPreferences } = storeToRefs(useNewSettingsStore())
  return currentCountry.value?.Id || userPreferences.value?.CountryId
}

// Merge all the region-specific constants into one
const allCountries = {
  ...EU_COUNTRIES_ID,
  ...NON_EU_COUNTRIES_ID,
}

export const isUK = () => getCurrentCountryId() === EU_COUNTRIES_ID['UnitedKingdom']
export const isGermany = () => getCurrentCountryId() === EU_COUNTRIES_ID['Germany']
export const isSweden = () => getCurrentCountryId() === EU_COUNTRIES_ID['Sweden']
export const isDenmark = () => getCurrentCountryId() === EU_COUNTRIES_ID['Denmark']
export const isSwitzerland = () => getCurrentCountryId() === EU_COUNTRIES_ID['Switzerland']
export const isFrance = () => getCurrentCountryId() === EU_COUNTRIES_ID['France']
export const isUAE = () => getCurrentCountryId() === NON_EU_COUNTRIES_ID['United Arab Emirates']

export function isDeGroup() {
  return (
    getCurrentCountryId() === EU_COUNTRIES_ID['Germany'] ||
    getCurrentCountryId() === EU_COUNTRIES_ID['Sweden'] ||
    getCurrentCountryId() === EU_COUNTRIES_ID['Denmark'] ||
    getCurrentCountryId() === EU_COUNTRIES_ID['Switzerland']
  )
}

export function isGmbhGroup() {
  // const settings = useNewSettingsStore()
  // return  _77Settings.Country.CompanyId == 2 // TODO: check what is the company id
  return false
}

export const isEUCountry = () => Object.values(EU_COUNTRIES_ID).includes(getCurrentCountryId())
export const isEuropeanCountry = () => Object.values(EUROPEAN_COUNTRIES_ID).includes(getCurrentCountryId())
export const getSelectedCountry = () => getCurrentCountryId()
